import { cn } from '#app/utils/misc'
import { type ClerkProduct } from '#types/product'
import LowInStockLabel from './low-in-stock'
import DiscountLabel from './product-discount'

const BottomLabels = ({
	product,
	hidden,
	storeParams,
}: {
	product: ClerkProduct
	hidden?: boolean
	storeParams?: string[]
}) => {
	return (
		<div
			className={cn(
				'absolute bottom-4 left-2 z-20 text-black-90 transition-all duration-300 ease-in-out md:left-4',
				hidden ? 'bottom-4 md:bottom-[22%]' : 'bottom-4',
			)}
		>
			<div className="flex flex-col space-y-1">
				<LowInStockLabel product={product} hidden={hidden} />
				<DiscountLabel product={product} storeParams={storeParams} />
			</div>
		</div>
	)
}

export default BottomLabels
