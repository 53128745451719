import { useLocation } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { type ClerkProduct } from '#types/product'

type ClerkPriceTagProps = {
	product: ClerkProduct
	storeParams?: string[]
}

export const ClerkPriceTag = ({ product, storeParams }: ClerkPriceTagProps) => {
	let { t } = useTranslation('product_details_page')
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const storeParam = searchParams.get('store')

	let price = product.price // Default to the global lowest price
	let listPrice = product.list_price // Default to the global original price
	let isDiscounted = product.on_sale && price < listPrice

	// Check if there is a specific store in the URL parameters
	if (storeParam) {
		const storeData = JSON.parse(product.data)
		const storePricing = storeData.find(
			(store: any) => store.store_key === storeParam,
		)
		if (storePricing) {
			// Find the lowest price in the store-specific data
			const storePrices = storePricing.prices
			const lowestStorePrice = storePrices.reduce((lowest: any, item: any) => {
				return item.price < lowest.price ? item : lowest
			}, storePrices[0])

			price = lowestStorePrice.price
			listPrice = lowestStorePrice.list_price
			isDiscounted = lowestStorePrice.on_sale
		}
	} else if (storeParams) {
		// Check if there is a specific store in the storeParams
		const storeData = JSON.parse(product.data)
		const storePricing = storeData.find(
			(store: any) => store.store_key === storeParams[0],
		)
		if (storePricing) {
			// Find the lowest price in the store-specific data
			const storePrices = storePricing.prices
			const lowestStorePrice = storePrices.reduce((lowest: any, item: any) => {
				return item.price < lowest.price ? item : lowest
			}, storePrices[0])

			price = lowestStorePrice.price
			listPrice = lowestStorePrice.list_price
			isDiscounted = lowestStorePrice.on_sale
		}
	}

	return (
		<div className="flex items-center md:space-x-4">
			{isDiscounted ? (
				<div className="flex items-baseline space-x-4">
					<PlentyBody size="sm" className="m-0 font-bold text-red ">{`${t(
						'from_label',
					)} ${price.toFixed(2)} kr`}</PlentyBody>
					<PlentyBody
						size="sm"
						className="text-green-40 line-through"
					>{`${listPrice.toFixed(2)} kr`}</PlentyBody>
				</div>
			) : (
				<PlentyBody size="sm" className="m-0">{`${price.toFixed(
					2,
				)} kr`}</PlentyBody>
			)}
		</div>
	)
}
