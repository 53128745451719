import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { type ClerkProduct } from '#types/product'

interface NewLabelProps {
	product: ClerkProduct
}

const NewLabel = ({ product }: NewLabelProps) => {
	let { t } = useTranslation('product_list_page')
	const sevenDaysInSeconds = 14 * 24 * 60 * 60
	const nowInSeconds = Math.floor(Date.now() / 1000)
	const isNew = nowInSeconds - product.created_at <= sevenDaysInSeconds

	if (!isNew) {
		return null
	}

	return (
		<div className="absolute left-2 top-2 z-20 rounded-[40px] bg-orange-10 px-4 py-[2px] opacity-80 md:left-4 md:top-4">
			<PlentyBody size="xs">{t('label_new')}</PlentyBody>
		</div>
	)
}

export default NewLabel
